import React from "react";

import video from "../Assets/video.mp4";

const Work = () => {

  return (
    <section id="demo">
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="primary-subheading">Demo</p>
          <h1 className="primary-heading-how-to-use">
            How to use Meat Injector
          </h1>
          <video controls style={{ maxWidth: "100%", height: "auto" }}>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
    </section>
  );
};

export default Work;
