import React from "react";
import sturdyNeedles from "../Assets/ProductBenefits/sturdy-needles.png";
import keepingYouSafe from "../Assets/ProductBenefits/keeping-you-safe.png";
import easyToClean from "../Assets/ProductBenefits/easy-to-clean.png";
import brandComparison from "../Assets/ProductBenefits/brand-comparison.png";

const ProductBenefits = () => {
  return (
    <section id="details">
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="primary-subheading">Details</p>

          <img
            className="productBenefits"
            src={sturdyNeedles}
            alt="Sturdy Stainless Steel Needles"
          />

          <img
            className="productBenefits"
            src={keepingYouSafe}
            alt="Keeping You Safe"
          />

          <img
            className="productBenefits"
            src={easyToClean}
            alt="Easy to Clean"
          />

          <img
            className="productBenefits"
            src={brandComparison}
            alt="Brand Comparison"
          />
        </div>
      </div>
    </section>
  );
};

export default ProductBenefits;
