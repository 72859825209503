import React from "react";

const Footer = () => {
  return (
    <section id="contact">
      <div className="contact-us">
        <p className="primary-subheading">Contact</p>
        <p>customerservice@thechubbybbq.com</p>
        <p className="trade-mark">Chubby BBQ™ by Starboard Merchants LLC</p>
      </div>
    </section>
  );
};

export default Footer;
