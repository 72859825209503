import React from "react";
import picture2 from "../Assets/picture2.png";

const About = () => {
  return (
    <section id="about">
      <div className="about-section-container">
        <div className="about-section-image-container">
          <img id="picture2" src={picture2} alt="" />
        </div>
        <div className="about-section-text-container">
          <p className="primary-subheading">About</p>
          <h1 className="primary-heading">No More Dry Meats</h1>
          <p className="primary-text-about">
            Our easy-to-use meat injector makes turkey, steak, pork, chicken,
            and lamb extra juicy and tender. You’ll definitely impress your
            family and friends during special occasions.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
