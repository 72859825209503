import React from 'react'
import NavBar from './NavBar'
import { FiArrowRight } from 'react-icons/fi'
import heroImage from '../Assets/heroImage.jpg'

const Home = () => {
  
  const handleCheckout = () => {
    console.log('checkout')
    window.open('https://square.link/u/5o8Ltq2A')
  }

  return (
    <section id='home'>
      <div className='home-container'>
          <NavBar/>
          <div className='home-banner-container'>
            <div className='home-bannerImage-container'>
            </div>
            <div className='home-text-section'>
              <h1 className='primary-heading'>Meat Injector Kit</h1>
              <p className='primary-text-home'>Making Food Extra Juicy and Tender</p>
              <p className='primary-text'>$29.99</p>
              <button onClick={() => handleCheckout()} className='secondary-button'>Buy Now <FiArrowRight/> </button>
              <p className='shipping-text'>3 day shipping included</p>
            </div>
            <div className='home-image-container'>
              <img src={heroImage} alt='meat injector kit' id='heroImage'/>
            </div>
          </div>
      </div>
    </section>



  )
}

export default Home