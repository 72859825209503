import React from "react";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <section id='reviews'>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="primary-subheading">Reviews</p>
          <h1 className="primary-heading">Happy Customer</h1>
        </div>
        <div className="testimonial-section-bottom">
          <p>
              This is a high quality, product from a veteran owned business.
              This is the first injector I have owned and it is very good quality so far. Easy to clean and easy to inject with the turkey and pork loin I have tried so far. Very pleased.
          </p>
          <div className="testimonials-stars-container">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2>Josh</h2>
        </div>
      </div>
    </section>


  );
};

export default Testimonial;