import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { HiOutlineBars3 } from "react-icons/hi2";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EmailIcon from "@mui/icons-material/Email";
import StarIcon from "@mui/icons-material/Star";
import { animateScroll } from "react-scroll";

const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "About",
      icon: <OutdoorGrillIcon />,
      sectionId: "about",
    },
    {
      text: "Demo",
      icon: <PlayArrowIcon />,
      sectionId: "demo",
    },
    {
      text: "Details",
      icon: <InfoIcon />,
      sectionId: "details",
    },
    {
      text: "Reviews",
      icon: <StarIcon />,
      sectionId: "reviews",
    },
    {
      text: "Contact",
      icon: <EmailIcon />,
      sectionId: "contact",
    },
  ];

  const handleLinkClick = (sectionId) => {
    animateScroll.scrollTo(document.getElementById(sectionId).offsetTop, {
      duration: 1000, // or any other value in milliseconds
      smooth: "easeInOutQuart", // or any other easing function
    });
  };

  return (
    <nav className="fixed-navbar">
      <h1 id="logo-name">Chubby BBQ</h1>
      <div className="navbar-links-container">
        {menuOptions.map((option) => (
          <a
            key={option.text}
            href={`#${option.sectionId}`}
            onClick={() => handleLinkClick(option.sectionId)}
          >
            {option.text}
          </a>
        ))}
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3
          className="navbar-menu-icon"
          onClick={() => setOpenMenu(true)}
        />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 170, textAlign: "center" }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={() => handleLinkClick(item.sectionId)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text}></ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </nav>
  );
};

export default NavBar;
